import * as PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import { capitalize } from '../helpers/utils';
import CityDetail from '../components/fromToCityCanadaDetail';
import Sidebar from '../components/Sidebar/sidebar';
import FlightCardsList from '../components/FlightCards/FlightCardsList';
import CallUs from '../components/CallUs/CallUs';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';

import Layout from '../components/layout';
import SubscribeForm from '../components/SubscribeForm';
import Trustpilot from '../components/Trustpilot';
import SEO from '../components/seo';
import OtherDestinations from '../components/OtherDestinations/OtherDestinations';

import { CURRENCY } from '../constants/currency';
import { isFlykart } from '../helpers/urlParams';
import configurePageData from '../helpers/configurePageData';

class CityTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      fromToCityCanadaJson: PropTypes.object.isRequired,
      allMarkdownRemark: PropTypes.object,
      aImage: PropTypes.object,
      fImage: PropTypes.object
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      configuredData: null
    };
  }

  async componentDidMount() {
    const dataConfig = await configurePageData(
      this.props.data.fromToCityCanadaJson.slug
    );
    if (dataConfig) {
      this.setState({
        configuredData: dataConfig
      });
    }
  }

  render() {
    const {
      fromToCityCanadaJson: fromToCityCanadaJSON,
      allMarkdownRemark
    } = this.props.data;
    const fromToCityCanadaJson = {
      ...fromToCityCanadaJSON,
      ...(this.state.configuredData && this.state.configuredData)
    };
    const { phoneNumber } = this.state;
    const path = [
      ...fromToCityCanadaJson.path,
      {
        name: fromToCityCanadaJson.destination,
        slug: fromToCityCanadaJson.slug
      }
    ];
    const slug = fromToCityCanadaJson.slug;
    const countryName =
      fromToCityCanadaJson.path.length >= 1
        ? capitalize(
            fromToCityCanadaJson.path[fromToCityCanadaJson.path.length - 1].name
          )
        : '';
    const title = `Flights to ${capitalize(fromToCityCanadaJson.title)} (${
      fromToCityCanadaJson.pairs[0].destinationIATA
    }), ${countryName} from ${CURRENCY.CANADA}${
      fromToCityCanadaJson.pairs[0].price
    }`;

    const { origin, destination } = fromToCityCanadaJson.pairs[0];

    const layoutTitle = `Find Cheap Flights from ${origin} to ${destination}`;
    const flightCardsListTitle = `Cheap flights from ${origin} to ${destination} from ${CURRENCY.CANADA}${fromToCityCanadaJson.pairs[0].price}`;
    const flykartTheme = isFlykart();
    const img = flykartTheme
      ? this.props.data.fImage.childImageSharp.fluid
      : this.props.data.aImage.childImageSharp.fluid;
    return (
      <Layout
        pageData={fromToCityCanadaJson}
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
        title={layoutTitle}
        image={img}
      >
        <SEO title={title} slug={slug} />
        <div className="main">
          <Breadcrumbs path={path} />
          <div className="page-content">
            <div className="flight-content">
              <FlightCardsList
                city={fromToCityCanadaJson}
                title={flightCardsListTitle}
                cheapestPrice={fromToCityCanadaJson.pairs[0].price}
              />
              <CallUs phoneNumber={phoneNumber} />
              <CityDetail
                city={fromToCityCanadaJson}
                htmlContent={
                  allMarkdownRemark.edges[0]
                    ? allMarkdownRemark.edges[0].node.html
                    : null
                }
              />
              {fromToCityCanadaJson.originNearest && (
                <OtherDestinations
                  data={fromToCityCanadaJson.originNearest}
                  text={`Flights to ${capitalize(
                    fromToCityCanadaJson.destination
                  )} from nearby airports `}
                />
              )}
              {fromToCityCanadaJson.destinationNearest && (
                <OtherDestinations
                  data={fromToCityCanadaJson.destinationNearest}
                  text={`Flights to nearby to ${capitalize(
                    fromToCityCanadaJson.destination
                  )} airports `}
                />
              )}
            </div>
            <Sidebar destination={capitalize(fromToCityCanadaJson.title)} />
          </div>
          <SubscribeForm isInnerPage />
        </div>
        <Trustpilot />
      </Layout>
    );
  }
}

export default CityTemplate;

export const pageQuery = graphql`
  query($title: String!, $image: String) {
    fromToCityCanadaJson(title: { eq: $title }) {
      ...FromToCity_Canada_details
    }
    allMarkdownRemark(filter: { frontmatter: { page: { eq: $title } } }) {
      edges {
        node {
          html
          frontmatter {
            title
            date
          }
        }
      }
    }
    aImage: file(relativePath: { in: [$image, "flight-background.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fImage: file(relativePath: { in: [$image, "flykart-img/flykart-bg.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
